:root {
  --primary-light: #070707;
  --primary: #000000;
  --primary-dark: #000000;
  --white: #FFFFFF;
  --greyLight-1: #b5bbc4;
  --greyLight-2: #797d89;
  --greyLight-3: #5e6370;
  --greyDark: #3a3f4a;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  width: 15rem;
  height: 4rem;
  border-radius: 1rem;
  box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 10px 10px;
  cursor: pointer;
  transition: .3s ease;
  background-color: #fff; /* Başlangıç arka plan rengi */
  color: #000; /* Başlangıç yazı rengi */

  &:hover {
    background-color: #000; /* Hover durumunda arka plan rengini siyah yap */
    color: #fff; /* Hover durumunda yazı rengini beyaz yap */
  }

  &__primary {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    background: var(--primary);
    box-shadow:inset .2rem .2rem 1rem var(--primary-light), 
              inset -.2rem -.2rem 1rem var(--primary-dark),
              0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
    color: var(--greyLight-1);

    &:hover { color: var(--white); }
    &:active {
      box-shadow:inset .2rem .2rem 1rem var(--primary-dark), 
             inset -.2rem -.2rem 1rem var(--primary-light);
    }
  }

  &__secondary {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    color: var(--greyDark);
    &:hover { color: var(--primary); }
    &:active {
      box-shadow: inset .2rem .2rem .5rem var(--greyLight-2), 
      inset -.2rem -.2rem .5rem var(--white);;
    }
  }

  p { 
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 50%; /* Butonların genişliği ekrana göre ayarlanacak */
  }
}